import {
  CREATE_MEMBERSHIP,
  FIND_MEMBERSHIP,
  GET_MEMBERSHIP_LIST,
  UPDATE_MEMBERSHIP
} from "@/store/modules/membership.module";

import { mixinPromise } from "@c@/helpers/mixin.promise";
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["membershipError", "membershipList", "membershipListMeta"])
  },
  methods: {
    storeMembership(formData, action = {
      begin: ()=>{ this.$emit('storing'); },
      after: (response, state)=>{ this.$emit('stored', {response, state}); },
    }){
      return mixinPromise(
        this.$store.dispatch(CREATE_MEMBERSHIP, formData),
        action
      );
    },
    updateMembership(formData, action = {
      begin: ()=>{ this.$emit('updating'); },
      after: (response, state)=>{ this.$emit('updated', {response, state}); },
    }){
      return mixinPromise(
        this.$store.dispatch(UPDATE_MEMBERSHIP, formData),
        action
      );
    },
    findMembership(id, action = {
      begin: ()=>{ this.showBusy('membershipBusy'); },
      after: (response, state)=>{ this.hideBusy('membershipBusy'); },
    }){
      return mixinPromise(
        this.$store.dispatch(FIND_MEMBERSHIP, id),
        action
      );
    },
    getMemberships(page = 1, title = '', action = {
      begin: ()=>{ this.showBusy('membershipBusy'); },
      after: (response, state)=>{ this.hideBusy('membershipBusy'); },
    }){
      return mixinPromise(
        this.$store.dispatch(GET_MEMBERSHIP_LIST, { page, title }),
        action
      );
    },
  }
}
