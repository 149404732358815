<template>
  <div class="edit-membership">
    <error :errors="membershipError" />
    <b-overlay :show="membershipBusy">
      <portlet title=" ئەزالىق تۈرى ئۇچۇرى تەھرىرلەش ">
        <template v-slot:body>
          <membership-form
            ref="membership"
            action="edit"
            :model-id="modelId"
            @loading="onLoading"
            @loaded="onLoaded"
            @updating="onLoading"
            @updated="onUpdated"
          />
        </template>
        <template v-slot:foot>
          <b-button id="submit" @click="handleSubmit" variant="primary">
            ساقلاش
          </b-button>
          <b-button @click="onClickReset" class="ml-2" variant="danger">
            بىكار قىلىش
          </b-button>
        </template>
      </portlet>
    </b-overlay>
  </div>
</template>

<script>
  import Portlet from "@v@/partials/content/Portlet";
  import BreadCrumbs from "@c@/breadcrumbs.service";
  import MembershipForm from "@v@/components/forms/MembershipForm";
  import Error from "@v@/partials/widgets/Error";
  import {debugConsole} from "@c@/helpers/helpers";
  import { mapGetters } from "vuex";
  import formBusyMixin from "@m@/common/formBusy.mixin";
  import sweetAlertMixin from "@m@/common/sweetAlert.mixin";
  export default {
    name: "index",
    components: { Portlet, MembershipForm, Error },
    mixins: [ formBusyMixin, sweetAlertMixin ],
    computed: {
      ...mapGetters(["membershipError"]),
      modelId(){
        if ( this.$route.hasOwnProperty('params') )
          return this.$route.params.id;
      },
    },
    data(){
      return {
        membershipBusy: false,
        form: {}
      };
    },
    mounted() {
      BreadCrumbs.setBreadCrumb([]);
      BreadCrumbs.addBreadCrumb({title: "VIP ئەزالىق تۈرى باشقۇرۇش", route: {name: 'membership.home'}});
      BreadCrumbs.addBreadCrumb({title: 'ئەزالىق تۈرى ئۇچۇرى تەھرىرلەش'});
      if ( this.$refs.hasOwnProperty('membership') ){
        this.form = this.$refs['membership'];
      }
    },
    methods: {
      handleSubmit(){
        this.form.onSubmit();
      },
      onLoading(){
        this.showBusy('membershipBusy');
        debugConsole({prefix: 'membership', message: 'onLoading'})
      },
      onLoaded({response, state}){
        this.hideBusy('membershipBusy');
        debugConsole({prefix: 'membership', message: `onLoaded: ${state}`});
      },
      onUpdated({response, state}){
        this.hideBusy('membershipBusy');
        debugConsole({prefix: 'membership', message: `onUpdated: ${state}`});
        if ( state === 'success' ){
          this.success({ message: 'ئەزالىق تۈرى ئۇچۇرى تەھرىرلەش مۇۋاپىقىيەتلىك بولدى.' });
        }
      },
      onClickReset(){
        this.$router.replace({name: 'membership.home'});
      },
    }
  }
</script>
