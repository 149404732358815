<template>
  <div class="membership-form">
    <b-row>
      <b-col md="2" sm="6">
        <b-form-group id="input-group-is_show" label="ئوچۇقمۇ ؟" label-for="is_show">
          <b-form-checkbox id="is_show" switch v-model="formData.is_show"/>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="3" sm="6">
        <b-form-group id="input-group-8" label="تۈر ماۋزوسى" label-for="title" >
          <b-form-input id="title" v-model="formData.title" type="text" :state="validateState('title')" aria-describedby="title-live-feedback" placeholder="ئەزالىق تۈرىنىڭ ماۋزوسىنى كىرگۈزۈڭ" />
          <b-form-invalid-feedback id="title-live-feedback">
            ئەزالىق تۈرىنىڭ ماۋزوسىنى كىرگۈزۈڭ
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
      <b-col md="2" sm="6">
        <b-form-group id="input-group-duration" label="ئۈنۈملۈك ۋاقتى" label-for="duration" >
          <b-form-input id="duration" style="direction: ltr; width: 100%;" v-model="formData.duration" type="number" :state="validateState('duration')" aria-describedby="duration-live-feedback" placeholder="نۆۋەتتىكى ئەزالىق تۈرىنىڭ ئۈنۈملۈك ۋاقتىنى كىرگۈزۈڭ" />
          <b-form-invalid-feedback id="duration-live-feedback">
            نۆۋەتتىكى ئەزالىق تۈرىنى ئۈنۈملۈك ۋاقتىنى كىرگۈزۈڭ
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
      <b-col md="2" sm="6">
        <b-form-group id="input-group-price" label="باھاسى" label-for="price" >
          <b-form-input id="price" style="direction: ltr; width: 100%;" v-model="formData.price" type="number" step="0.01" :state="validateState('price')" aria-describedby="price-live-feedback" placeholder=" باھاسىنى كىرگۈزۈڭ" />
          <b-form-invalid-feedback id="price-live-feedback">
            ئەزالىق تۈرىنىڭ تەڭگە سانىنى كىرگۈزمىسىڭىز نەق پۇل باھاسىنى كىرگۈزۈڭ
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
      <b-col md="2" sm="6">
        <b-form-group id="input-group-score" label="تەڭگە باھاسى" label-for="score" >
          <b-form-input id="score" style="direction: ltr; width: 100%;" v-model="formData.score" type="number" :state="validateState('score')" aria-describedby="score-live-feedback" placeholder=" تەڭگە سانىنى كىرگۈزۈڭ" />
          <b-form-invalid-feedback id="score-live-feedback">
            نۆۋەتتىكى ئەزالىق تۈرىنىڭ نەق پۇل باھاسىنى كىرگۈزمىسىڭىز تەڭگە باھاسىنى كىرگۈزۈڭ
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
      <b-col md="3" sm="6">
        <b-form-group id="input-group-9" label=" تەرتىبى" label-for="sort" >
          <b-form-input  style="direction: ltr; width: 100%;" id="sort" v-model="formData.sort" type="number" placeholder="تۈرنىڭ تەرتىبىنى كىرگۈزۈڭ، قۇرۇق قويسىڭىزمۇ بولىدۇ" />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col >
        <b-form-group id="input-group-description" label="چۈشەندۈرلىشى" label-for="description" >
          <b-form-textarea id="description" v-model="formData.description" type="text" :state="validateState('description')" aria-describedby="description-live-feedback" placeholder="ئەزالىق تۈرىنىڭ چۈشەندۈرلىشىنى كىرگۈزۈڭ" />
          <b-form-invalid-feedback id="description-live-feedback">
            ئەزالىق تۈرىنىڭ چۈشەندۈرلىشىنى كىرگۈزۈڭ
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>
<script>
  import formBusyMixin from "@m@/common/formBusy.mixin";
  import Portlet from "@v@/partials/content/Portlet";
  import { validationMixin } from "vuelidate";
  import membershipMixin from "@m@/membership.mixin";
  import { required, requiredIf } from "vuelidate/lib/validators";
  import sweetAlertMixin from "@m@/common/sweetAlert.mixin";

  export default {
    name: "MembershipForm",
    mixins: [ formBusyMixin, validationMixin, membershipMixin, sweetAlertMixin ],
    props: {
      action: {
        type: String,
        default: "create"
      }
    },
    validations(){
      return {
        formData: {
          title: { required },
          duration: { required },
          price: {
            required: requiredIf(()=>{
              return ! this.formData.score;
            })
          },
          score: {
            required: requiredIf(()=>{
              return ! this.formData.price;
            })
          },
          description: { required },
        }
      };
    },
    components: { Portlet },
    computed: {},
    created() {
      if ( this.action === 'edit' ){
        let id = this.$route.params.id;
        this.findMembership(id)
          .then(({data})=>{
            this.formData = data;
          })
          .catch(()=>{
            return this.$router.replace({name: 'membership.home'});
          })
      }
    },
    data(){
      return {
        formData: {
          title: '',
          duration: 1,
          price: null,
          score: null,
          description: '',
          is_show: true,
          sort: null
        }
      };
    },
    methods: {
      validateState(name, parent = null) {
        if ( parent ){
          const { $dirty, $error } = this.$v.formData[parent][name];
          return $dirty ? !$error : null;
        }
        const { $dirty, $error } = this.$v.formData[name];
        return $dirty ? !$error : null;
      },
      onSubmit(){
        this.$v.formData.$touch();
        if (this.$v.formData.$anyError) {
          this.warning({message: 'تولدۇرۇشقا تېگىشلىك جەدىۋەلنى تۇلۇق تولدۇرۇڭ'});
          return;
        }
        if ( this.action === 'create' ){
          this.storeMembership(this.formData)
            .then(()=>{
              return this.$router.replace({name: 'membership.home'});
            })
        }else if ( this.action === 'edit' ){
          this.updateMembership(this.formData)
            .then(()=>{
              this.success({message: 'تەھرىرلەش مۇۋاپىقىيەتلىك بولدى!'});
            })
        }
      },
      onReset(){
        return this.$router.replace({name: 'membership.home'});
      }
    }
  }
</script>

<style scoped></style>
